<template>
  <b-card-code>
    <!-- Search Form -->
    <b-card no-body style="width: 100%">
      <b-card-code class="bg-light-secondary">
        <div class="d-flex justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <h4 class="mb-0">Search</h4>
          </div>
          <div class="d-flex justify-content-end mr-1">
            <router-link :to="'/vulnerability/add-vulnerability'">
              <b-button
                type="button"
                variant="outline-primary"
                class="mr-1 ml-auto float-right"
                size="sm"
              >
                Add Vulnerability
              </b-button>
            </router-link>
            <router-link :to="'/assets/upload-nessus-report'">
              <b-button
                type="button"
                variant="outline-primary"
                class="ml-auto float-right mr-1"
                size="sm"
              >
                Upload Vulnerability
              </b-button>
            </router-link>
          </div>
        </div>

        <b-form inline style="display: flex; width: 100%">
          <div
            class="row"
            style="
              padding: 10px;
              width: 100%;
              display: inline-flex;
              flex-wrap: nowrap;
            "
          >
            <b-form-input
              class="ml-1"
              style="width: 100%"
              id="name"
              v-model="name_filter"
              placeholder="Vulnerability Name"
            />

            <v-select
              v-model="group_filter"
              multiple
              label="text"
              class="ml-1"
              @search="fetchGroup"
              placeholder="--Search Group--"
              :options="group_filter_options"
              autocomplete
              style="width: 100%"
              :reduce="(group_filter) => group_filter.value"
            />
            <v-select
              v-model="severity_filter"
              label="text"
              class="ml-1"
              placeholder="--Select Severity--"
              :options="severity_filter_options"
              autocomplete
              style="width: 100%"
              :reduce="(severity_filter) => severity_filter.value"
            />
          </div>
          <div
            class="row"
            style="
              padding: 10px;
              width: 100%;
              display: inline-flex;
              flex-wrap: nowrap;
            "
          >
            <v-select
              v-model="status_filter"
              label="text"
              class="ml-1"
              placeholder="--Select Status--"
              :options="status_filter_options"
              autocomplete
              style="width: 52%"
              :reduce="(status_filter) => status_filter.value"
            />
            <b-form-input
              class="ml-1 w-100"
              id="assetname"
              v-model="assetname_filter"
              name="name"
              placeholder="Search with Asset Name"
            />
          </div>
          <div class="w-100 d-flex justify-content-end align-items-center">
            <b-button
              style="width: 15%"
              class="mr-2"
              variant="secondary"
              @click="refreshFn"
              size="sm"
            >
              <span>
                <feather-icon icon="RefreshCwIcon" class="mr-50" />Reset</span
              >
            </b-button>
            <b-button
              style="width: 15%"
              class="mr-2"
              variant="primary"
              @click="searchFn"
              size="sm"
            >
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>
                <feather-icon icon="SearchIcon" class="mr-50" />Search</span
              >
            </b-button>
          </div>
        </b-form>
      </b-card-code>
    </b-card>
    <!-- Search Form ends here-->
    <div class="row my-2 align-items-center">
      <div class="col-6">
        <div v-if="!loading" class="clearfix">
          {{ total_vulnerabilties_count }} Vulnerabilities
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div
          class="mr-1 d-flex justify-content-end align-items-center"
          style="width: 35%"
        >
          <span class="mr-1">Per page</span>
          <b-form-select
            class=""
            size="sm"
            style="width: 30%"
            v-model="perPage"
            :options="page_filter_options"
            @change="searchFn()"
          >
          </b-form-select>
        </div>
      </div>
      <!-- <div class="col-6 d-flex justify-content-end">
        <b-button variant="primary" size="sm" @click="getCSV()">
          <span v-if="isloadingCsv"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else
            ><feather-icon icon="DownloadIcon" class="mr-50" />Export to
            CSV</span
          >
        </b-button>
      </div> -->
    </div>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <br />
    <div v-if="!loading">
      <b-table
        responsive
        id="vulnTable"
        :fields="visibleFields"
        :items="items"
        :per-page="perPage"
        v-if="items.length"
        hover
      >
        <template v-slot:head(occurrences)>
          <div class="d-flex justify-content-around">
            <span>Occurrences</span>
            <div class="d-flex flex-column">
              <feather-icon
                icon="ChevronUpIcon"
                class="mr-50"
                @click="setSort(1)"
                style="height: 10px"
              />
              <feather-icon
                icon="ChevronDownIcon"
                class="mr-50"
                @click="setSort(2)"
                style="height: 10px"
              />
            </div>
          </div>
        </template>
        <template v-slot:head(num_assets_affected)>
          <div class="d-flex justify-content-around">
            <span>No of Asset Affected</span>
            <div class="d-flex flex-column">
              <feather-icon
                icon="ChevronUpIcon"
                class="mr-50"
                @click="setSort(3)"
                style="height: 10px"
              />
              <feather-icon
                icon="ChevronDownIcon"
                class="mr-50"
                @click="setSort(4)"
                style="height: 10px"
              />
            </div>
          </div>
        </template>
        <template v-slot:head(severity)>
          <div class="d-flex justify-content-between">
            <span>Severity</span>
            <div class="d-flex flex-column">
              <feather-icon
                icon="ChevronUpIcon"
                class="mr-50"
                @click="setSort(5)"
                style="height: 10px"
              />
              <feather-icon
                icon="ChevronDownIcon"
                class="mr-50"
                @click="setSort(6)"
                style="height: 10px"
              />
            </div>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
        </template>
        <template #cell(name)="data">
          <a class="text-truncate" @click="redirectToSummary(data.item.name)">
            <del
              v-if="data.item.status == 1"
              v-html="breakTextIntoLines(data.item.name)"
            >
            </del>
            <span v-else v-html="breakTextIntoLines(data.item.name)"></span>
          </a>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | formatdate }}
        </template>
        <template v-slot:cell(asset)="data">
          <router-link :to="'/assets/scan/' + data.item.asset.asset_id">{{
            data.item.asset.asset_name
          }}</router-link>
        </template>

        <template #cell(occurrences)="data">
          <div class="text-center">
            <b-avatar
              size="sm"
              variant="secondary"
              :text="data.item.occurrences"
            />
          </div>
        </template>
        <template #cell(num_assets_affected)="data">
          <div class="text-center">
            <b-avatar
              size="sm"
              variant="secondary"
              :text="data.item.num_assets_affected"
            />
          </div>
        </template>
        <template v-slot:cell(severity)="data">
          <b-badge
            style="background-color: #991d28"
            v-if="data.item.severity_score == 4"
            >Critical</b-badge
          >
          <b-badge variant="danger" v-else-if="data.item.severity_score == 3"
            >High</b-badge
          >
          <b-badge variant="warning" v-else-if="data.item.severity_score == 2"
            >Medium</b-badge
          >
          <b-badge variant="primary" v-else-if="data.item.severity_score == 1"
            >Low</b-badge
          >
          <b-badge variant="success" v-else-if="data.item.severity_score == 0"
            >Info</b-badge
          >
          <b-badge variant="secondary" v-else>Unknown</b-badge>
        </template>
        <!-- #cell(status)="data" -->
        <template v-slot:cell(status)="data">
          <b-badge variant="danger" v-if="data.item.status == 0">Open</b-badge>
          <b-badge variant="success" v-if="data.item.status == 1"
            >Closed</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 2"
            >False Positive</b-badge
          >
          <b-badge variant="success" v-if="data.item.status == 3"
            >Accepted</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 4"
            >Assigned</b-badge
          >
          <b-badge variant="warning" v-if="data.item.status == 5"
            >Mitigated</b-badge
          >
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-4"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>

    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="vulnTable"
      use-router
    />
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BSidebar,
  VBToggle,
  BBadge,
  BFormInput,
  BRow,
  BFormSelect,
  BJumbotron,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BAvatar,
  BDropdown,
  BDropdownForm,
  BDropdownItem,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
// import VulnDetail from "../../Asset/components/vuln_details.vue";
// import TaskForm from "../components/mitigate_bulk.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BSpinner,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BSidebar,
    VBToggle,
    // VulnDetail,
    BBadge,
    BForm,
    BFormInput,
    BFormCheckbox,
    BRow,
    BFormSelect,
    BJumbotron,
    BFormGroup,
    vSelect,
    // TaskForm,
    BAvatar,
    BDropdown,
    BDropdownForm,
    BDropdownItem,
    flatPickr,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      query: false,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 100,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#", visible: true },
        { key: "name", label: "Name", visible: true },
        { key: "occurrences", label: "Occurrences", visible: true },
        {
          key: "num_assets_affected",
          label: "No of Asset Affected",
          visible: true,
        },
        // { key: "selected_vulns", label: "select", visible: true },
        // { key: "name", label: "Name", sortable: true, visible: true },
        { key: "severity", label: "Severity", visible: true },
        { key: "status", label: "Status", visible: true },
        // { key: "asset", label: "Asset", visible: true },
        // {
        //   key: "asset.asset_nickname",
        //   label: "Nick Name",
        //   sortable: true,
        //   visible: false,
        // },
        // { key: "port", label: "Port", visible: true },
        // { key: "protocol", label: "Protocol", visible: false },
        // { key: "is_verified", label: "Verified", visible: true },
        // { key: "is_assigned", label: "Assigned", visible: true },
        // { key: "created_at", label: "Added At", visible: false },
        // { key: "cvss_score", label: "CVSS Score", visible: false },
        // { key: "cve", label: "CVE", visible: false },
        // { key: "path", label: "Path", visible: false },
        // { key: "parameter", label: "Parameter", visible: false },
        // { key: "updated_at", label: "Updated At", visible: false },
        // { key: "source", label: "Source", visible: false },
        // { key: "actions", label: "Actions" },
      ],
      asset_id: 0,
      organization_id: 0,
      vuln: {},
      is_assigned: null,
      assigned_filter_options: [
        { value: null, text: "--Is Assigned?--" },
        { value: false, text: "No" },
        { value: "mitigation", text: "Assigned for Mitigation" },
        { value: "retest", text: "Assigned for Retest" },
      ],
      severity_filter: null,
      // severity_filter: [],
      severity_filter_options: [
        { value: null, text: "--Select Severity--" },
        { value: 0, text: "Info" },
        { value: 1, text: "Low" },
        { value: 2, text: "Medium" },
        { value: 3, text: "High" },
        { value: 4, text: "Critical" },
      ],
      // severity_filter_options: [
      //   { value: null, text: "--Select Severity--" },
      //   { value: "info", text: "Info" },
      //   { value: "low", text: "Low" },
      //   { value: "medium", text: "Medium" },
      //   { value: "high", text: "High" },
      //   { value: "critical", text: "Critical" },
      // ],
      name_filter: "",
      asset_filter: null,
      asset_options: [],
      status_filter: null,
      // status_filter: [],
      // status_filter_options: [
      //   { value: null, text: "--Select Status--" },
      //   { value: "Open", text: "Open" },
      //   { value: "Closed", text: "Closed" },
      //   { value: "False Positive", text: "False Positive" },
      //   { value: "Accepted", text: "Accepted" },
      //   { value: "Mitigated", text: "Mitigated" },
      // ],
      status_filter_options: [
        { value: null, text: "--Select Status--" },
        { value: 0, text: "Open" },
        { value: 1, text: "Closed" },
        { value: 2, text: "False Positive" },
        { value: 3, text: "Accepted" },
        // { value: 4, text: "Assigned" },
        { value: 5, text: "Mitigated" },
      ],
      // group_filter: null,
      // group_filter_options: [{ value: null, text: "--Select Group--" }],
      group_filter: [],
      group_filter_options: [],
      port_filter: "",
      port_type: null,
      port_type_options: [
        { value: null, text: "Protocol Type" },
        { value: "tcp", text: "TCP" },
        { value: "udp", text: "UDP" },
      ],
      selectedVulns: [],
      with_selected_options: [
        { value: null, text: "--Actions--" },
        // { value: "export_to_csv", text: "Export to CSV" },
        { value: "mitigate", text: "Mitigate" },
        { value: "mark_as_closed", text: "Mark as Closed" },
        { value: "mark_as_open", text: "Mark as Open" },
        { value: "mark_as_false_positive", text: "Mark as False Positive" },
        { value: "accept", text: "Accept the Risk" },
        { value: "delete", text: "Delete" },
        { value: "mark_as_verified", text: "Mark as verified" },
        { value: "mark_as_unverified", text: "Mark as unverified" },
        { value: "mark_as_exploited", text: "Mark as exploited" },
        { value: "mark_as_unexploited", text: "Mark as unexploited" },
      ],
      selected_sort_options: [
        { value: null, text: "--Sort With--" },
        { value: "updated_at", text: "Last Updated" },
        { value: "added_at", text: "Added At" },
      ],
      selected_sort: null,

      is_verified: null,
      verified_filter_options: [
        { value: null, text: "--Is Verified?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],
      is_exploited: null,
      exploited_filter_options: [
        { value: null, text: "--Is Exploited?--" },
        { value: true, text: "Yes" },
        { value: false, text: "No" },
      ],

      selected_action: null,
      isCheckAllFilterResults: false,
      isCheckAll: false,
      is_staff: this.$store.state.app.user.is_staff,
      // organization: this.$store.state.app.org_id,
      // organization : this.$store.state.app.user.profile.organization,
      organization: null,
      organizations: [],
      users: [{ value: null, text: "--Select User--" }],
      filter: [],
      is_search_on: false,
      total_vulnerabilties_count: 0,
      loading: false,
      label_options: [],
      label_filter: [],
      multi_asset_filter: [],
      multi_asset_options: [],
      // rangeDate: null,
      fromDate: null,
      endDate: null,
      assetname_filter: "",

      isloading: false,
      isloadingCsv: false,

      isHidden: false,

      divId: "",
      activeTab: "",
      currentStatus: this.$route.query.status,
      currentSeverity: this.$route.query.severity,
      currentGroup: this.$route.query.groupid,
      sortValue: null,
      searchGroup: null,
      page_filter_options: [
        { value: "10", text: "10" },
        { value: "20", text: "20" },
        { value: "50", text: "50" },
        { value: "100", text: "100" },
        { value: "500", text: "500" },
      ],
      expandedItems: [],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    this.load();
    this.searchFn();
    if (this.$route.query.group_id) {
      this.loadGroupsAndSearch();
    }
    if (this.$route.query.asset_id) {
      this.loadAsset();
    }
  },
  watch: {
    perPage(newVal, oldVal) {
      this.$router.replace({ query: { page: 1 } });
      this.currentPage = 1; // Reset currentPage to 1 when perPage changes
      this.searchFn();
    },
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
    searchGroup() {
      this.searchFn(false);
    },
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm A");
      }
    },
  },
  props: {
    exploited: {
      type: Boolean,
      required: true,
    },
    searchGroup: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    // toggleExpand(index) {
    //   const currentIndex = this.expandedItems.indexOf(index);
    //   if (currentIndex === -1) {
    //     this.expandedItems.push(index);
    //   } else {
    //     this.expandedItems.splice(currentIndex, 1);
    //   }
    // },
    // isExpanded(itemId) {
    //   return this.expandedItems.includes(itemId);
    // },
    // truncateName(name) {
    //   if (name.length > 30) {
    //     return name.slice(0, 30);
    //   }
    //   return name;
    // },
    breakTextIntoLines(text) {
      const maxCharacters = 55;
      const words = text.split(/\s+/);
      const lines = [];

      let currentLine = "";

      for (const word of words) {
        if ((currentLine + word).length <= maxCharacters) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines.join("<br>");
    },
    setSort(sortValue) {
      this.sortValue = sortValue;
      this.searchFn();
    },
    redirectToSummary(vulnName) {
      this.$router.push({
        path: "/assets/vulnerabilities/summary",
        query: { vulnName }, // Pass the "name" as a query parameter
      });
    },
    redirectName(name) {
      this.$emit("row-click", name); // Emit the click event to the parent component
    },
    refreshFn() {
      this.name_filter = "";
      this.group_filter = [];
      this.severity_filter = null;
      // this.severity_filter = [];
      this.status_filter = null;
      // this.status_filter = [];
      this.multi_asset_filter = [];
      this.port_type = null;
      this.port_filter = "";
      this.is_verified = null;
      this.is_exploited = null;
      this.is_assigned = null;
      this.label_filter = [];
      this.fromDate = null;
      this.endDate = null;
      (this.assetname_filter = ""), this.searchFn();
    },
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      let current_path = this.$router.currentRoute.path + "?page=" + pageNum;
      return current_path;
    },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      this.users = [];

      if (this.is_staff) {
        this.loading = true;
        this.organization = this.$store.state.app.org_id;
      } else {
        // this.users = [];
        this.loading = true;
        // this.organization = this.$store.state.app.user.profile.organization;
        this.organization = this.$store.state.app.org_id;
      }
    },
    fetchGroup: function (search) {
      if (search.length > 0) {
        // this.users = [];

        if (this.organization == null) {
          this.filter = [];
          this.is_search_on = false;
          this.items = [];
          return;
        }
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/group/list-all-asset-groups?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.group_filter_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].group_id,
              text: res.data[key].group_name,
            };
            self.group_filter_options.push(a);
          });
          const queryGroup = this.$route.query.group_id;
          // if (queryGroup) {
          //   const groupValues = Array.isArray(queryGroup)
          //     ? queryGroup.map(Number)
          //     : [Number(queryGroup)];
          //   this.group_filter = this.group_filter_options
          //     .filter((option) => groupValues.includes(option.value))
          //     .map((option) => option.value);
          // }
        });
      }
    },
    loadGroupsAndSearch: function () {
      // this.users = [];

      if (this.organization == null) {
        this.filter = [];
        this.is_search_on = false;
        this.items = [];
        return;
      }

      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/list-all-asset-groups?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.group_filter_options = [];
        res.data.map(function (value, key) {
          let a = {
            value: res.data[key].group_id,
            text: res.data[key].group_name,
          };
          self.group_filter_options.push(a);
        });
        const queryGroup = this.$route.query.group_id;
        if (queryGroup) {
          const groupValues = Array.isArray(queryGroup)
            ? queryGroup.map(Number)
            : [Number(queryGroup)];
          this.group_filter = this.group_filter_options
            .filter((option) => groupValues.includes(option.value))
            .map((option) => option.value);
        }
      });
    },
    loadAsset: function () {
      this.multi_asset_options = [];
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        self.multi_asset_options = [];
        res.data.results.map(function (value, key) {
          let a = {
            value: res.data.results[key].asset_id,
            text:
              res.data.results[key].asset_nickname +
              " (" +
              res.data.results[key].assetType.asset_type_name +
              ")",
          };
          // console.log(res.data[key].profile.organization)
          self.multi_asset_options.push(a);
          // self.loading = false;
        });
        const queryAsset = this.$route.query.asset_id;
        if (queryAsset) {
          const assetValues = Array.isArray(queryAsset)
            ? queryAsset.map(Number)
            : [Number(queryAsset)];
          this.multi_asset_filter = this.multi_asset_options
            .filter((option) => assetValues.includes(option.value))
            .map((option) => option.value);
        }
      });
    },
    searchFn: function (reset = true) {
      this.isloading = true;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/grouped-vulns?query=true&page=" +
        this.currentPage +
        "&items_per_page=" +
        this.perPage +
        "&org_id=" +
        this.organization;
      if (this.exploited) {
        url = url + "&exploited=true";
      }
      if (this.$route.query.group_id && this.group_filter.length === 0) {
        const groupId = this.$route.query.group_id;
        url = url + `&group_id=${groupId}`;
        this.group_filter = this.$route.query.group_id;
        this.filter.push({ group_id: this.$route.query.group_id });
      }
      if (this.$route.query.severity && this.severity_filter == null) {
        const queryString = this.$route.query.severity
          .map((item) => `severity=${item}`)
          .join("&");
        url = url + `&severity=` + `&${queryString}`;
        this.severity_filter = this.$route.query.severity; // Set selected filter values
        this.filter.push({ severity: this.severity_filter });
      }
      if (this.name_filter != "") {
        url = url + "&name=" + this.name_filter;
        this.filter.push({ name: this.name_filter });
      }

      if (this.assetname_filter != "") {
        url = url + "&asset_name=" + this.assetname_filter;
        this.filter.push({ asset_name: this.assetname_filter });
      }

      if (this.severity_filter != null) {
        url = url + "&severity_score=" + this.severity_filter;
        this.filter.push({ severity_score: this.severity_filter });
      }
      if (this.status_filter != null) {
        url = url + "&status=" + this.status_filter;
        this.filter.push({ status: this.status_filter });
      }

      if (this.group_filter.length != 0) {
        const queryString = this.group_filter
          .map((item) => `group_id=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.filter.push({ group_id: this.group_filter });
      }

      // if(sortValue == 1 || sortValue == 2 || sortValue == 3 || sortValue == 4 || sortValue == 5 || sortValue == 6){
      //   debugger
      //   url = url + "&sort_by=" + sortValue;
      // }
      if (this.sortValue != null) {
        url = url + "&sort_by=" + this.sortValue;
      }

      if (this.filter.length) this.is_search_on = true;

      this.query = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.total_vulnerabilties_count = res.data.count;
        //setInterval(function(){self.updateCheckAll()},3000);
        // this.updateCheckAll();
        this.loading = false;
        this.isloading = false;
      });
    },

    fetchOptions: function (search, loading) {
      if (search.length > 2) {
        this.multi_asset_options = [];
        this.loading = true;
        // loading(true);
        // Load assets here
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.multi_asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].assetType.asset_type_name +
                ")",
            };
            // console.log(res.data[key].profile.organization)
            self.multi_asset_options.push(a);
            self.loading = false;
          });
          const queryAsset = this.$route.query.asset_id;
          if (queryAsset) {
            const assetValues = Array.isArray(queryAsset)
              ? queryAsset.map(Number)
              : [Number(queryAsset)];
            this.multi_asset_filter = this.multi_asset_options
              .filter((option) => assetValues.includes(option.value))
              .map((option) => option.value);
          }
        });
      }
    },

    csvDownload: function () {
      let data = {
        vulnerabilties: this.selectedVulns,
      };
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/vulns/export-as-csv/",
        responseType: "blob",
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `vulnerabilties-${Math.random()}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        console.log(res, "csv file");
        if (res.status == 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "downloaded successfully",
              // title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedVulns = [];
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isloadingCsv = false;
      });
    },
    // for csv download btn
    getCSV: function () {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        if (confirm("Do you really want to download?")) {
          this.isloadingCsv = true;
          this.csvDownload();
        }
        this.selected_action = null;
      }
    },

    doBulkAction: function () {
      if (!this.selectedVulns.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      }

      switch (this.selected_action) {
        case "mitigate":
          // Action to mitigate
          // Pop up for the task assignee
          this.$root.$emit(
            "bv::toggle::collapse",
            "sidebar-task-handler-taskform"
          );
          break;
        case "mark_as_closed":
          if (confirm("Do you really want to close the selected issues?")) {
            this.changeStatus(1);
          }
          this.selected_action = null;
          break;
        case "mark_as_false_positive":
          if (
            confirm(
              "Do you really want to mark as false positive the selected issues?"
            )
          ) {
            this.changeStatus(2);
          }
          this.selected_action = null;
          break;
        case "mark_as_open":
          if (confirm("Do you really want to (re)open the selected issues?")) {
            this.changeStatus(0);
          }
          this.selected_action = null;
          break;
        case "accept":
          if (
            confirm("Do you really want to accept the risk of selected issues?")
          ) {
            this.changeStatus(3);
          }
          this.selected_action = null;
          break;
        case "delete":
          if (confirm("Do you really want to delete the selected issues?")) {
            this.changeStatus(9);
          }
          this.selected_action = null;
          break;
        case "mark_as_verified":
          if (confirm("Do you really want to mark all as verified?")) {
            this.changeStatus(10);
          }
          this.selected_action = null;
          break;
        case "mark_as_unverified":
          if (confirm("Do you really want to mark all as un-verified?")) {
            this.changeStatus(11);
          }
          this.selected_action = null;
          break;
        // new actions
        case "mark_as_exploited":
          if (confirm("Do you really want to mark all as exploited?")) {
            this.changeStatus(12);
          }
          this.selected_action = null;
          break;
        case "mark_as_unexploited":
          if (confirm("Do you really want to mark all as un-exploited?")) {
            this.changeStatus(13);
          }
          this.selected_action = null;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
::v-deep .v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
  max-height: 300px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bg-background {
  background: #fff !important;
}
[dir] .dark-layout .bg-background {
  // background: #283046 !important;
  background: #253e42 !important;
  border-color: #404656;
}
</style>